import "antd/dist/reset.css";
// import AOS from "aos";

import { Wrapper } from "@googlemaps/react-wrapper";
import { ThemeProvider } from "@material-tailwind/react";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import "./app.css";
import Routers from "./routes";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  // AOS.init();
  useEffect(() => {
    // Load your data here
    setIsLoading(false);
  }, []);
  // useEffect(() => {
  // 	setTimeout(() => {
  // 		// Load your data here
  // 		setIsLoading(false);
  // 	}, 1000); // 3-second delay
  // }, []);

  return (
    <Wrapper apiKey="AIzaSyDTIwWeWwlkNv93r04T92ShOScAp8wnSpQ">
      <ThemeProvider>
        {isLoading ? (
          <motion.div
            className=" w-full h-screen flex flex-col justify-center items-center md:text-6xl sm:text-5xl text-4xl font-semibold italic text-defblue"
            // animate={{ scale: [1, 1.1, 1.2] }}
            transition={{ duration: 0.8, repeat: Infinity, ease: "linear" }}
          >
            <motion.div
              className=" sm:w-[200px] sm:h-[200px] w-[100px] h-[100px] bg-defblue mb-20"
              animate={{ rotate: [0, 360] }}
              transition={{ duration: 0.8, repeat: Infinity, ease: "linear" }}
            ></motion.div>
            {/* <ClimbingBoxLoader
							color={"#1C4E84"}
							loading={isLoading}
							// cssOverride={override}
							size={30}
							aria-label="Loading Spinner"
							data-testid="loader"
							speedMultiplier={2}
						/> */}
            <TypeAnimation
              sequence={[
                "Loading...", // Types 'One'
                500,
                "...Loading",
                500,
              ]}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
              // style={{ fontSize: "2em", display: "inline-block" }}
            />
          </motion.div>
        ) : (
          <Routers />
        )}
      </ThemeProvider>
    </Wrapper>
  );
};

export default App;
