import { FloatButton } from 'antd';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../components/Header';

const DefensysLayout = () => {
	const [menuActive, setMenuActive] = useState(false);

	return (
		<div>
			<Header menuActive={menuActive} setMenuActive={setMenuActive} />
			<FloatButton.BackTop visibilityHeight={800} />
			{/* <div className=" h-[93vh] bg-defensys-bg bg-cover bg-center" id="home"></div> */}
			<Outlet />
		</div>
	);
};

export default DefensysLayout;
